<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#00000"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 text-left>
        <span style="color: #000; font-size: 20px; font-family: poppinsbold"
          >Contact List</span
        >
      </v-flex>
      <v-flex xs12 pt-4 v-if="contact.length > 0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-uppercase">Name</th>
                <th class="text-left text-uppercase">Email</th>
                <th class="text-left text-uppercase">Country</th>
                <th class="text-left text-uppercase">State</th>
                <th class="text-center text-uppercase">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr style="height: 90px" v-for="(item, i) in contact" :key="i">
                <td class="text-left">{{ item.name }}</td>
                <td class="text-left">{{ item.email }}</td>
                <td class="text-left">{{ item.country }}</td>
                <td class="text-left">{{ item.state }}</td>
                <td class="text-left">
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm3 lg4 align-self-center>
                      <v-btn
                        plain
                        text
                        :to="'/contactDetails?contact=' + item._id"
                      >
                        <v-icon color="black">mdi-eye</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 sm3 lg4 align-self-center>
                      <v-dialog v-model="item.delete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="red" v-on="on" v-bind="attrs"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to delete this
                            Contact?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItem(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                  </v-layout>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <v-flex xs12 v-else>
        <span class="textField2"> No Updates Found!! ! </span>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap justify-center v-if="contact.length > 0">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#FDAC53"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      currentPage: 1,
      pages: 0,
      count: 15,
      appLoading: false,
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      msg: null,
      contact: [],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/contact/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.contact = response.data.data;
            this.pages = response.data.pages;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      this.appLoading = true;
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/contact/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>